<template>
  <section
    class="sale-office-root"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div :class="{'container': $viewport.isGreaterOrEquals('sm')}">
      <Map
        v-if="officePoints && officePoints.length"
        :office-points="officePoints"
        :projects="projectsList || []"
        :active-project="activeProject"
        :custom-height="true"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import {errorParserServerResponse, type Ref} from '#imports';
import { ref } from '#imports';
import type { MapPoint } from '~/components/map/Map.vue';
import { catalogStore as useCatalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { storeToRefs } from 'pinia';

const catalogStore = useCatalogStore();
const appConfigStore = useAppConfigStore();

const { catalogTree } = storeToRefs(catalogStore);
const { vars, config } = storeToRefs(appConfigStore);
const $viewport = useViewport();

type MapWithDotsItemType = {
	lat:string;
	lon:string;
	subtitle:string;
	title:string;
}

type MapWithDotsType = Omit<IBlockDefaultProps, 'values' | 'cfgs' > & {
	values: {
		center_lat:string;
		center_lon:string;
	items:MapWithDotsItemType[];
	};
	cfgs: SectionTypes;
}

const { $customSectionClasses, $api } = useNuxtApp();
const { yandexMapApiKey } = useRuntimeConfig().public
const props = defineProps<MapWithDotsType>();

const officePoints: Ref<MapPoint[]> = ref([]);
const projectsList = catalogTree?.value[0]?.items;
const activeProject: Ref<{ id: string | number }> = ref({ id: '' });

const getSalesOfficePoint = async () => {
  const addressOffice = vars?.value['Адрес офиса продаж'];
  if (!addressOffice) {
    return;
  }

  const addresses: string[] = addressOffice
    .split('<br/>')
    .filter((item: string) => item);

  const pointOfficeList: Awaited<any>[] = await Promise.all(addresses.map(async (address: string, index: number) => {
    const currentCity = config.value.city.model.name || '';
    const currentAddress = address.replaceAll('&nbsp;', ' ')

    const response = await $api.agent.get(`/geocode-maps?geocode=${currentCity} ${currentAddress}`)
      .then((res) => {
        return res?._data
      })
      .catch((err) => {
        console.log('map-with-dots error:', errorParserServerResponse(err));
      });

    if (!response) {
      return;
    }

    const GeoObject = response?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject || null;
    if (!GeoObject) {
      return;
    }

    const [lat, lon]: [number, number] = (GeoObject?.Point?.pos || '').split(' ').reverse();

    return {
      id: `office-sales-${index}`,
      name: 'Офис продаж',
      subtitle: address,
      variant: 'primary',
      lat: lat,
      lon: lon,
      isOffice: true
    };
  }));
  officePoints.value = pointOfficeList;
};

onMounted(async () => {
  await getSalesOfficePoint();
});
</script>
